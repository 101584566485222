<template>
  <div class="link">
    <el-row :gutter="20">
      <el-col :span="4"><div class="blank_box"></div></el-col>
      <el-col :span="16">
        <div class="container title" flex column between>
          <p>联系我们</p>
          <p></p>
        </div>
      </el-col>
      <el-col :span="4"><div class="blank_box"></div></el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="4"><div class="blank_box"></div></el-col>
      <el-col :span="16">
        <div class="container link_content">
          <el-row :gutter="20">
            <el-col :span="11"
              ><div class="left">
                <img
                  src="../assets/img/联系我们.png"
                  alt=""
                  width="100%"
                  height="100%"
                /></div
            ></el-col>
            <el-col :span="13"
              ><div class="right">
                <el-form
                  :model="ruleForm"
                  :rules="rules"
                  ref="ruleForm"
                  label-width="0"
                  class="demo-ruleForm"
                >
                  <el-form-item label="">
                    <el-input
                      type="text"
                      v-model="ruleForm.name"
                      autocomplete="off"
                      placeholder="请输入姓名"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="phone">
                    <el-input
                      v-model.number="ruleForm.phone"
                      autocomplete="off"
                      placeholder="请输入联系电话"
                      :maxlength="11"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="email">
                    <el-input
                      v-model="ruleForm.email"
                      placeholder="请输入联系邮箱"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input
                      v-model="ruleForm.wechat_or_qq"
                      placeholder="微信/QQ"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="content">
                    <el-input
                      v-model="ruleForm.content"
                      type="textarea"
                      placeholder="请填写留言内容"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <button
                      class="key_button link_button"
                      @click="submitForm('ruleForm')"
                    >
                      提交留言
                    </button>
                    <!-- <el-button
                      type="warning"
                      plain
                      @click="submitForm('ruleForm')"
                      >提交留言</el-button
                    > -->
                  </el-form-item>
                </el-form>
              </div></el-col
            >
          </el-row>
        </div>
      </el-col>
      <el-col :span="4"><div class="blank_box"></div></el-col>
    </el-row>
  </div>
</template>
<script>
import { comtactUs } from "@/https/api.js";
export default {
  data() {
    var checkPhone = function (rule, value, callback) {
      if (value) {
        let regx = new RegExp(/^[1][3,4,5,7,8,9][0-9]{9}$/);
        if (regx.test(value)) {
          return callback();
        }
        return callback(new Error("电话号码格式错误"));
      }
      return callback();
    };
    var checkEmail = function (rule, value, callback) {
      if (value) {
        let regx = new RegExp(
          /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
        );
        if (!regx.test(value)) {
          return callback(new Error("邮箱格式错误"));
        }
        return callback();
      }
      return callback();
    };
    return {
      rules: {
        email: [
          {
            type: "eamil",
            trigger: "blur",
            validator: checkEmail,
          },
        ],
        phone: [
          {
            type: "number",
            trigger: "blur",
            validator: checkPhone,
          },
          // {
          //   type: "number",
          //   max: 12,
          //   message: "电话号码格式错误",
          //   trigger: "blur",
          // },
        ],
      },
      ruleForm: {
        name: "",
        phone: "",
        email: "",
        wechat_or_qq: "",
        content: "",
      },
    };
  },
  mounted() {
    this.resetForm("ruleForm");
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //提交
          if (this.ruleForm.content) {
            comtactUs({
              ...this.ruleForm,
            }).then((res) => {
              if (res.code === 0) {
                this.$alert("提交成功!", "提示", {
                  confirmButtonText: "确定",
                });
                this.ruleForm = {};
              }
            });
          } else {
            this.$alert("您还未填写留言不能提交哦!", "提示", {
              confirmButtonText: "确定",
            });
          }
        } else {
          this.$alert("您填写的信息不正确!", "提示", {
            confirmButtonText: "确定",
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.link {
  padding-top: 60px;
  background-color: #fafafa;
  .title {
    margin: 0 0 40px 0;
  }
}
.right {
  width: 100%;
  .demo-ruleForm {
    width: 100%;
    /deep/ .el-form-item__content {
      text-align: left;
    }
  }
  .link_button {
    background-color: #fafafa;
    border: 1px solid #f78900;
    color: rgba(247, 137, 0, 1);
    width: 140px;
    height: 45px;
    border-radius: 4px;
  }
  .link_button:hover {
    color: #fff;
    background-color: rgba(247, 137, 0, 1);
  }
}
.left {
  width: 100%;
  text-align: left;
  padding: 0 0 70px 0;
  img {
    width: 467px;
    height: 344px;
  }
}

@media screen and(max-width:1200px) {
  .title {
    font-size: 25px;
    line-height: 30px;
    height: 60px;
    margin-top: 30px;
  }
  .left {
    height: 350px;
  }
  .link_content {
    height: 430px;
    // padding-top: 30px;
  }
  .right {
    .link_button {
      width: 100px;
      height: 40px;
      font-size: 14px;
    }
    /deep/ .el-input_inner {
      width: 100%;
      font-size: 14px;
    }
    .demo-ruleForm {
      /deep/ .el-button {
        width: 120px;
        height: 40px;
        font-size: 14px;
      }
    }
    /deep/ .el-form-item {
      margin-bottom: 10px;
    }
  }
  .left {
    img {
      width: 311px;
      height: 228px;
    }
  }
}
@media screen and(min-width:1200.1px) and(max-width:1500px) {
  .link_content {
    padding-top: 80px;
    height: 505px;
  }
  .link {
    height: 756px;
  }
  .title {
    margin-top: 80px;
  }
  .right {
    /deep/ .el-input_inner {
      width: 100%;
      font-size: 16px;
    }
    .demo-ruleForm {
      /deep/ .el-button {
        width: 150px;
        height: 50px;
        font-size: 16px;
      }
    }
    /deep/ .el-form-item {
      margin-bottom: 15px;
    }
  }
  .left {
    height: 425px;
    img {
      width: 350px;
      height: 257px;
    }
  }
}
@media screen and(min-width:1500.1px) and(max-width:1700px) {
  .left {
    height: 425px;
    img {
      width: 435px;
      height: 320px;
    }
  }
}
@media screen and(min-width:1700.1px) {
  .left {
    height: 425px;
    img {
      width: 467px;
      height: 344px;
    }
  }
}
</style>