<template>
  <div class="service">
    <el-row :gutter="20">
      <el-col :span="24">
        <Banner :options="banner_options">
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="slot_content">
                <p class="title">
                  <span>专业</span>品质， <span>志诚</span>服务
                </p>
                <p class="border_p">
                  <span></span>
                </p>
                <p>点击立即咨询联系我们获取你的专属解决方案吧!</p>
                <div>
                  <button class="key_button" @click="$router.push('/join')">
                    立即咨询
                  </button>
                </div>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
        </Banner>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="scope">
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16"
              ><div class="title" flex column between>
                <p>服务范围</p>
                <p></p></div
            ></el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16"
              ><div class="container scope_content" flex wrap>
                <div
                  v-for="(item, index) in scope_list"
                  :key="index"
                  flex
                  column
                  center
                  align
                >
                  <i class="iconfont" :class="item.icon"></i>
                  <!-- <img src="" alt="" width="100%" height="100%" /> -->
                  <!-- <i class="iconfont" :class="item.icon"></i> -->
                  <p>{{ item.name }}</p>
                </div>
              </div></el-col
            >
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="case">
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="title" flex column between>
                <p>服务案例</p>
                <p></p>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="title_left container">
                <p>移动端</p>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="move_swiper">
                <swiper :options="swiperOption" v-if="lunbo_phone.length > 0">
                  <swiper-slide
                    v-for="(item, index) in lunbo_phone"
                    :key="index"
                    flex
                    center
                    align-end
                  >
                    <img
                      src="../../assets/img/矢量智能对象.png"
                      width="100%"
                      height="100%"
                      class="bg"
                      v-if="item.over_flag || item.click_flag"
                    />
                    <img
                      src="../../assets/img/手机.png"
                      width="100%"
                      height="100%"
                      class="bg"
                      v-else
                    />
                    <div
                      class="content"
                      flex
                      column
                      center
                      align
                      @mouseenter="changePhone(item, 1)"
                      @mouseleave="changePhone(item, 2)"
                      @click="getCurPhone(item)"
                    >
                      <img :src="item.logo" width="100%" height="100%" />
                      <p>{{ item.title }}</p>
                      <p>{{ item.content }}</p>
                    </div>
                  </swiper-slide>
                </swiper>
                <div
                  class="swiper-button-prev"
                  slot="button-prev"
                  @click="prevPhone(cur_phone.id)"
                >
                  <i class="iconfont icon-zuoshuangjiantou"></i>
                </div>
                <div
                  class="swiper-button-next"
                  slot="button-next"
                  @click="nextPhone(cur_phone.id)"
                >
                  <i class="iconfont icon-youshuangjiantou"></i>
                </div>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="move_content container" flex around align>
                <div class="left" flex column center align>
                  <p flex align>
                    <span></span>
                    {{ cur_phone.title }}
                  </p>
                  <p>{{ cur_phone.contain }}</p>
                </div>
                <div class="right" flex center align>
                  <img
                    :src="cur_phone.img"
                    alt=""
                    height="100%"
                    width="100%"
                    :style="
                      cur_phone.id === 2 || cur_phone.id === 4
                        ? { width: '70%', height: '90%' }
                        : cur_phone.id === 3
                        ? { width: '65%', height: '93%' }
                        : {}
                    "
                  />
                </div>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="title_left container">
                <p>PC端</p>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="pc_swiper container">
                <swiper :options="pcOption" v-if="lunbo_pc.length > 0">
                  <swiper-slide
                    v-for="(item, index) in lunbo_pc"
                    :key="index"
                    flex
                    center
                    align-start
                  >
                    <img
                      src="../../assets/img/有色电脑.png"
                      width="100%"
                      height="100%"
                      class="bg"
                      id="pc_bg"
                      v-if="item.over_flag || item.click_flag"
                    />
                    <img
                      src="../../assets/img/电脑.png"
                      width="100%"
                      height="100%"
                      class="bg"
                      id="pc_bg"
                      v-else
                    />
                    <div
                      class="content"
                      flex
                      column
                      center
                      align
                      @click="getCurPc(item)"
                      @mouseenter="changeBg(item, 1)"
                      @mouseleave="changeBg(item, 2)"
                    >
                      <img
                        :src="item.logo"
                        width="100%"
                        height="100%"
                        :style="
                          index === 1 ? { width: '140px', height: '65px' } : {}
                        "
                      />
                      <p
                        :style="
                          index === 1
                            ? { padding: '20px 0', height: '50px' }
                            : {}
                        "
                      >
                        {{ item.content }}
                      </p>
                    </div>
                  </swiper-slide>
                </swiper>
                <div
                  class="swiper-button-prev"
                  slot="button-prev"
                  @click="prevPc(cur_pc.id)"
                >
                  <i class="iconfont icon-zuoshuangjiantou"></i>
                </div>
                <div
                  class="swiper-button-next"
                  slot="button-next"
                  @click="nextPC(cur_pc.id)"
                >
                  <i class="iconfont icon-youshuangjiantou"></i>
                </div>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="move_content container" flex around align>
                <div class="right pc_right" flex center align>
                  <img :src="cur_pc.img" alt="" width="100%" height="100%" />
                </div>
                <div class="left" flex column center align>
                  <p flex align>
                    <span></span>
                    {{ cur_pc.content }}
                  </p>
                  <p>{{ cur_pc.contain }}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <Contact></Contact>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Banner from "@/components/banner.vue";
import Contact from "@/components/link.vue";

export default {
  components: {
    Banner,
    Contact,
  },
  data() {
    return {
      banner_options: {
        bg_img: require("@/assets/img/定制banner.png"),
      },
      scope_list: [
        {
          icon: "icon-APP",
          name: "移动app定制",
        },
        {
          icon: "icon-xiaochengxu",
          name: "微信公众号/小程序开发",
        },
        {
          icon: "icon-wangzhanjianshe1",
          name: "网站/系统建设",
        },
        {
          icon: "icon-xianshangtuiguang",
          name: "线上推广",
        },
        {
          icon: "icon-dian",
          name: "弱电工程服务",
        },
        {
          icon: "icon-zhinengyingjian",
          name: "智能硬件改造",
        },
        {
          icon: "icon-shengji2",
          name: "智能化升级",
        },
        {
          icon: "icon-xinxihuajianshe",
          name: "政府/企业单位信息化建设",
        },
      ],
      cur_phone: {
        id: 1,
        logo: require("@/assets/img/vitel_logo.png"),
        title: "VITTE打车",
        content: "您的智能打车软件首选APP",
        img: require("@/assets/img/VITTE打车.png"),
        contain:
          "VITTE打车APP可提供线上快速预约附近的顺风车或专车服务，满足不同需求人群；实时地图google导航，精准定位，实时查询人、车和路线；一键拨号安全机制设置，应对紧急情况，保证用户安全。",
        over_flag: false,
        click_flag: true,
      },
      lunbo_phone: [
        {
          id: 1,
          logo: require("@/assets/img/vitel_logo.png"),
          title: "VITTE打车",
          content: "您的智能打车软件首选APP",
          img: require("@/assets/img/VITTE打车.png"),
          contain:
            "VITTE打车APP可提供线上快速预约附近的顺风车或专车服务，满足不同需求人群；实时地图google导航，精准定位，实时查询人、车和路线；一键拨号安全机制设置，应对紧急情况，保证用户安全。",
          over_flag: false,
          click_flag: true,
        },
        {
          id: 2,
          logo: require("@/assets/img/cheyib.png"),
          title: "车衣宝",
          content: "定制化开发的车衣投保理赔小程序",
          img: require("@/assets/img/车衣宝.png"),
          contain:
            "为中国人保”xpel车衣保护“项目定制化开发了车衣投保理赔小程序，助力中国人保“Xpel care+”保障服务计划顺利、高效开展。",
          over_flag: false,
          click_flag: false,
        },
        {
          id: 3,
          logo: require("@/assets/img/lepao-icon.7c560951.png"),
          title: "乐跑",
          content: "生命不息，运动不止共享健身-乐跑APP",
          img: require("@/assets/img/图层 22.png"),
          contain:
            "乐跑是一款智能健身软件，为用户提供一个高效、便捷、自主的智能健身场馆，帮助用户定制健身计划以及体质、体能在线检测，同时也为用户提供海量在线视频教学。",
          over_flag: false,
          click_flag: false,
        },
        {
          id: 4,
          logo: require("@/assets/img/1.png"),
          img: require("@/assets/img/茶府.png"),
          title: "溢阳茶府",
          content: "就餐、品茶、商务洽谈的上佳首选",
          contain:
            "溢阳茶府移动在线商场是溢阳茶府综合性餐饮管理平台的用户端，为客户提供点餐、外卖、预约、购买周边商品、会员充值等功能，满足客户足不出户即可享受餐饮服务的需求。",
          over_flag: false,
          click_flag: false,
        },
      ],
      swiperOption: {
        slidesPerView: 4,
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        //开启循环模式
        // loop: true,
        //开启鼠标滚轮控制Swiper切换
        mousewheel: true,
      },
      pcOption: {
        slidesPerView: 3,
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        //开启循环模式
        // loop: true,
        //开启鼠标滚轮控制Swiper切换
        mousewheel: true,
      },
      lunbo_pc: [
        {
          id: 1,
          logo: require("@/assets/img/zgyh-icon..png"),
          content: "中国银行高级企业客户管理系统",
          img: require("@/assets/img/中国银行.png"),
          contain:
            "中国银行高级企业客户管理系统将项目、客户信息等进行关联，集中管理，帮助银行提高内部协助效率。",
          over_flag: false,
          click_flag: true,
        },
        {
          id: 2,
          logo: require("@/assets/img/鲸云logo.png"),
          img: require("@/assets/img/jy-admin.png"),
          content: "鲸云阅读网站",
          contain:
            "鲸云阅读是一款主打轻小说的在线书城，鲸云用户端提供写作、作品上传、在线免费/付费阅读、用户充值等功能，后台管理端提供用户、作品、运营、财务管理，同时提供数据、报表分析等功能。",
          over_flag: false,
          click_flag: false,
        },
        {
          id: 3,
          logo: require("@/assets/img/1.png"),
          content: "溢阳茶府仓储管理系统（WMS）",
          img: require("@/assets/img/yy-admin.png"),
          contain:
            "溢阳茶府综合性餐饮管理平台是一套综合性的餐饮管理系统，集预定、点菜、厨房分单、前台结算、客户管理、会员卡管理和后台的购、销、存、成本分析、经营分析等完备的一体化功能。",
          over_flag: false,
          click_flag: false,
        },
      ],
      cur_pc: {
        id: 1,
        logo: require("@/assets/img/zgyh-icon..png"),
        content: "中国银行高级企业客户管理系统",
        img: require("@/assets/img/中国银行.png"),
        contain:
          "中国银行高级企业客户管理系统将项目、客户信息等进行关联，集中管理，帮助银行提高内部协助效率。",
        over_flag: false,
        click_flag: true,
      },
      cur_pc_id: 1,
      cur_phone_id: 1,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.selectPC(this.cur_pc_id);
    this.selectPhone(this.cur_phone_id);
  },
  watch: {
    cur_pc_id() {
      this.selectPC(this.cur_pc_id);
    },
    cur_phone_id() {
      this.selectPhone(this.cur_phone_id);
    },
  },
  methods: {
    getCurPhone(row) {
      this.lunbo_phone.forEach((item) => {
        if (item.id == row.id) {
          item.click_flag = true;
          this.cur_phone = row;
          this.cur_phone_id = row.id;
        } else {
          item.click_flag = false;
        }
      });
      this.selectPhone(row.id);
    },
    getCurPc(row) {
      this.lunbo_pc.forEach((item) => {
        if (item.id == row.id) {
          item.click_flag = true;
          this.cur_pc = row;
          this.cur_pc_id = row.id;
        } else {
          item.click_flag = false;
        }
      });
      this.selectPC(row.id);
    },
    changeBg(row, type) {
      if (type === 1) {
        this.lunbo_pc.forEach((item) => {
          if (item.id == row.id) {
            item.over_flag = true;
          } else {
            item.over_flag = false;
          }
        });
      } else {
        row.over_flag = false;
      }
    },
    changePhone(row, type) {
      if (type === 1) {
        this.lunbo_phone.forEach((item) => {
          if (item.id == row.id) {
            item.over_flag = true;
          } else {
            item.over_flag = false;
          }
        });
      } else {
        row.over_flag = false;
      }
    },
    prevPhone(id) {
      if (id > 1) {
        this.lunbo_phone.forEach((item) => {
          if (item.id === id - 1) {
            item.click_flag = true;
            this.cur_phone = item;
            this.cur_phone_id = item.id;
          } else {
            item.click_flag = false;
          }
        });
      } else {
        this.lunbo_phone[this.lunbo_phone.length - 1].click_flag = true;
        this.cur_phone = this.lunbo_phone[this.lunbo_phone.length - 1];
        this.cur_phone_id = this.lunbo_phone[this.lunbo_phone.length - 1].id;
        for (let i = 0; i < this.lunbo_phone.length - 1; i++) {
          this.lunbo_phone[i].click_flag = false;
        }
      }
    },
    nextPhone(id) {
      if (id < this.lunbo_phone[this.lunbo_phone.length - 1].id) {
        this.lunbo_phone.forEach((item) => {
          if (item.id === id + 1) {
            item.click_flag = true;
            this.cur_phone = item;
            this.cur_phone_id = item.id;
          } else {
            item.click_flag = false;
          }
        });
      } else {
        this.lunbo_phone[0].click_flag = true;
        this.cur_phone = this.lunbo_phone[0];
        this.cur_phone_id = this.lunbo_phone[0].id;
        for (let i = 1; i < this.lunbo_phone.length; i++) {
          this.lunbo_phone[i].click_flag = false;
        }
      }
    },
    prevPc(id) {
      if (id > 1) {
        this.lunbo_pc.forEach((item) => {
          if (item.id === id - 1) {
            item.click_flag = true;
            this.cur_pc = item;
            this.cur_pc_id = item.id;
          } else {
            item.click_flag = false;
          }
        });
      } else {
        this.lunbo_pc[this.lunbo_pc.length - 1].click_flag = true;
        this.cur_pc = this.lunbo_pc[this.lunbo_pc.length - 1];
        this.cur_pc_id = this.lunbo_pc[this.lunbo_pc.length - 1].id;
        for (let i = 0; i < this.lunbo_pc.length - 1; i++) {
          this.lunbo_pc[i].click_flag = false;
        }
      }
    },
    nextPC(id) {
      if (id < this.lunbo_pc[this.lunbo_pc.length - 1].id) {
        this.lunbo_pc.forEach((item) => {
          if (item.id === id + 1) {
            item.click_flag = true;
            this.cur_pc = item;
            this.cur_pc_id = item.id;
          }else {
            item.click_flag = false
          }
        });
      } else {
        this.lunbo_pc[0].click_flag = true;
        this.cur_pc = this.lunbo_pc[0];
        this.cur_pc_id = this.lunbo_pc[0].id;
        for(let i = 1; i < this.lunbo_pc.length; i++){
          this.lunbo_pc[i].click_flag = false;
        }
      }
    },
    selectPC(id) {
      this.lunbo_pc.forEach((item) => {
        if (id == item.id) {
          item.bg_img = require("@/assets/img/有色电脑.png");
        } else {
          item.bg_img = require("@/assets/img/电脑.png");
        }
      });
    },
    selectPhone(id) {
      this.lunbo_phone.forEach((item) => {
        if (id == item.id) {
          item.bg_img = require("@/assets/img/矢量智能对象.png");
        } else {
          item.bg_img = require("@/assets/img/手机.png");
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.service {
  min-width: 1200px;
}
.slot_content {
  > p {
    text-align: left;
    color: #fff;
    letter-spacing: 2px;
  }
  > p:last-of-type {
    font-size: 20px;
    padding-top: 20px;
  }
  .title {
    font-size: 72px;
    > span {
      color: rgba(247, 137, 0, 1);
    }
  }
  .border_p {
    height: 4px;

    > span {
      background-color: #fff;
      display: inline-block;
      width: 580px;
      height: 4px;
    }
  }
  .company {
    font-size: 48px;
  }
}

.scope_content {
  height: 400px;
  //   background: #f6f8f8;
  background-color: #fff;
  box-shadow: 0px 0px 16px 4px rgba(117, 156, 255, 0.1);
  border-radius: 4px;
  margin: 100px 0;
  .iconfont {
    font-size: 70px;
    color: rgba(102, 102, 102, 1);
  }
  > div {
    width: 25%;
    height: 50%;
    border: 1px solid #fbfbfb;
    border-top: none;
    border-top: 4px solid #fff;
  }
  > div:hover {
    border-top: 4px solid rgba(247, 137, 0, 1);
    background-color: rgba(247, 238, 228, 1);
    p {
      color: rgba(247, 137, 0, 1);
    }
    .iconfont {
      color: rgba(247, 137, 0, 1);
    }
  }
  p {
    color: rgba(102, 102, 102, 1);
    font-size: 18px;
    padding: 8px 0;
    font-family: Source Han Sans SC;
  }
}

.case {
  .title_left {
    padding-left: 15px;
    height: 34px;
    border-left: 6px solid rgba(251, 121, 26, 1);
    text-align: left;
    line-height: 34px;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
  }
  .pc_swiper {
    width: 100%;
    height: 269px;
    position: relative;
    /deep/ .swiper-container {
      width: 86%;
      height: 100%;
    }
    /deep/ .swiper-button-next {
      right: 30px !important;
    }
    /deep/ .swiper-button-prev {
      left: 30px !important;
    }
    /deep/ .swiper-slide {
      width: 325px !important;
      height: 100%;
      margin: 0 26px 0 0;
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 130;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
      .content {
        width: 94%;
        height: 80%;
        padding: 20px 22px;
        background-color: rgba(255, 255, 255, 0);
        cursor: pointer;
        position: relative;
        top: 10px;
        z-index: 140;
        border-radius: 40px;
        img {
          width: 100px;
          height: 100px;
        }
        > p:nth-of-type(1) {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #4d4d4d;
          opacity: 0.92;
          // padding: 10px 0;
        }
      }
      .content:hover {
        background-color: #fef9f2;
        > p {
          color: rgba(251, 121, 26, 1);
        }
      }
    }
  }

  .move_swiper {
    width: 100%;
    height: 435px;
    position: relative;
    /deep/ .swiper-container {
      width: 79%;
      height: 100%;
    }
    /deep/ .swiper-button-next {
      right: 60px !important;
    }
    /deep/ .swiper-button-prev {
      left: 60px !important;
    }
    /deep/ .swiper-slide {
      width: 228px !important;
      height: 100%;
      margin: 0 30px 0 0;
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 140;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
      .content {
        width: 94%;
        height: 93%;
        padding: 0 20px;
        position: relative;
        z-index: 140;
        background-color: rgba(255, 255, 255, 0);
        cursor: pointer;
        border-radius: 0 0 20px 20px;
        top: -3px;
        left: -0.5px;
        img {
          width: 100px;
          height: 100px;
        }
        > p:nth-of-type(1) {
          padding: 60px 0 30px 0;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #4d4d4d;
        }
        > p:nth-of-type(2) {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 36px;
          opacity: 0.92;
        }
      }
      .content:hover {
        background-color: #fef9f2;
        > p {
          color: rgba(251, 121, 26, 1);
        }
      }
    }
  }
  /deep/ .swiper-button-next:after {
    content: none;
  }

  /deep/ .swiper-button-prev:after {
    content: none;
  }

  /deep/.swiper-button-prev,
  /deep/ .swiper-button-next {
    .iconfont {
      font-size: 70px;
      color: rgba(153, 153, 153, 1);
    }
    .iconfont:hover {
      color: rgba(251, 121, 26, 1);
    }
  }
  .move_content {
    height: 500px;
    margin: 80px 0 100px 0;
    background: #fef9f2;
    > div {
      width: 50%;
      height: 100%;
    }
    .left {
      padding: 100px 60px;
      > p:nth-of-type(1) {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        line-height: 50px;
        span {
          margin-right: 18px;
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: rgba(251, 121, 26, 1);
        }
      }
      > p:nth-of-type(2) {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 50px;
        padding-top: 30px;
        text-align: left;
      }
    }
    .right {
      padding: 20px 0;
      img {
        height: 418px;
        width: 488px;
      }
    }
    .pc_right {
      img {
        width: 422px;
        height: 352px;
      }
    }
  }
}

@media screen and(max-width:1200px) {
  .slot_content {
    top: 150px;
    > p:last-of-type {
      font-size: 16px;
      padding-top: 15px;
    }
    .border_p {
      height: 4px;
      > span {
        width: 580px;
        height: 4px;
      }
    }
    .title {
      font-size: 50px;
    }
  }
  .title {
    font-size: 25px;
    line-height: 30px;
    height: 60px;
  }
  .scope_content {
    height: 300px;
    p {
      font-size: 16px;
    }
    .iconfont {
      font-size: 35px;
    }
  }
  .case {
    .title_left {
      padding-left: 15px;
      height: 34px;
      line-height: 34px;
      font-size: 20px;
    }
    .pc_swiper {
      height: 180px;
      /deep/ .swiper-container {
        width: 86%;
      }
      /deep/ .swiper-button-next {
        right: 25px !important;
      }
      /deep/ .swiper-button-prev {
        left: 18px !important;
      }
      /deep/ .swiper-slide {
        width: 31.5% !important;
        margin: 0 15px 0 0;
        .content {
          padding: 20px 18px;
          img {
            width: 60px;
            height: 60px;
          }
          > p:nth-of-type(1) {
            font-size: 18px;
          }
        }
      }
    }

    .move_swiper {
      height: 300px;
      /deep/ .swiper-container {
        width: 79%;
      }
      /deep/ .swiper-button-next {
        right: 30px !important;
      }
      /deep/ .swiper-button-prev {
        left: 30px !important;
      }
      /deep/ .swiper-slide {
        width: 22.55% !important;
        margin: 0 20px 0 0;
        .content {
          padding: 25px 20px;
          img {
            width: 60px;
            height: 60px;
          }
          > p:nth-of-type(1) {
            padding: 30px 0 15px 0;
            font-size: 18px;
          }
          > p:nth-of-type(2) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
    /deep/.swiper-button-prev,
    /deep/ .swiper-button-next {
      .iconfont {
        font-size: 55px;
      }
    }
    .move_content {
      height: 300px;
      margin: 40px 0 50px 0;
      .left {
        padding: 50px 40px;
        > p:nth-of-type(1) {
          font-size: 20px;
          line-height: 30px;
          span {
            margin-right: 10px;
          }
        }
        > p:nth-of-type(2) {
          font-size: 14px;
          line-height: 30px;
          padding-top: 20px;
        }
      }
      .right {
        padding: 10px 0;
        img {
          height: 220px;
          width: 257px;
        }
      }
      .pc_right {
        padding: 70px 0 60px 60px;
        img {
          width: 362px;
          height: 302px;
        }
      }
    }
  }
}
@media screen and(min-width:1500.1px) and(max-width:1700px) {
  .slot_content {
    top: 350px;
    .border_p {
      margin: 28px 0;
    }
  }
  .case {
    .title_left {
      padding-left: 15px;
      height: 34px;
      line-height: 34px;
      font-size: 20px;
    }
    .pc_swiper {
      height: 240px;
      /deep/ .swiper-container {
        width: 86%;
      }
      /deep/ .swiper-button-next {
        right: 25px !important;
      }
      /deep/ .swiper-button-prev {
        left: 18px !important;
      }
      /deep/ .swiper-slide {
        width: 32.1% !important;
        margin: 0 15px 0 0;
        .content {
          padding: 20px 18px;
          img {
            width: 80px;
            height: 80px;
          }
          > p:nth-of-type(1) {
            font-size: 18px;
          }
        }
      }
    }

    .move_swiper {
      height: 400px;
      /deep/ .swiper-container {
        width: 79%;
      }
      /deep/ .swiper-button-next {
        right: 30px !important;
      }
      /deep/ .swiper-button-prev {
        left: 30px !important;
      }
      /deep/ .swiper-slide {
        width: 23% !important;
        margin: 0 20px 0 0;
        .content {
          padding: 25px 20px;
          img {
            width: 80px;
            height: 80px;
          }
          > p:nth-of-type(1) {
            padding: 30px 0 15px 0;
            font-size: 18px;
          }
          > p:nth-of-type(2) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
    /deep/.swiper-button-prev,
    /deep/ .swiper-button-next {
      .iconfont {
        font-size: 65px;
      }
    }
    .move_content {
      height: 400px;
      margin: 80px 0 100px 0;
      .left {
        padding: 80px 50px;
        > p:nth-of-type(1) {
          font-size: 22px;
          line-height: 40px;
          span {
            margin-right: 15px;
          }
        }
        > p:nth-of-type(2) {
          font-size: 16px;
          line-height: 40px;
          padding-top: 25px;
        }
      }
      .right {
        padding: 10px 0;
        img {
          height: 300px;
          width: 350px;
        }
      }
      .pc_right {
        padding: 70px 0 60px 60px;
        img {
          width: 392px;
          height: 327px;
        }
      }
    }
  }
}
@media screen and(min-width:1200.1px) and(max-width:1500px) {
  .slot_content {
    top: 350px;
    .border_p {
      margin: 20px 0;
    }
  }
  .scope_content {
    height: 350px;
    p {
      font-size: 16px;
    }
    .iconfont {
      font-size: 50px;
    }
  }
  .case {
    .title_left {
      padding-left: 15px;
      height: 34px;
      line-height: 34px;
      font-size: 20px;
    }
    .pc_swiper {
      height: 220px;
      /deep/ .swiper-container {
        width: 86%;
      }
      /deep/ .swiper-button-next {
        right: 25px !important;
      }
      /deep/ .swiper-button-prev {
        left: 18px !important;
      }
      /deep/ .swiper-slide {
        width: 32.1% !important;
        margin: 0 15px 0 0;
        .content {
          padding: 20px 18px;
          img {
            width: 80px;
            height: 80px;
          }
          > p:nth-of-type(1) {
            font-size: 18px;
          }
        }
      }
    }

    .move_swiper {
      height: 340px;
      /deep/ .swiper-container {
        width: 79%;
      }
      /deep/ .swiper-button-next {
        right: 30px !important;
      }
      /deep/ .swiper-button-prev {
        left: 30px !important;
      }
      /deep/ .swiper-slide {
        width: 23% !important;
        margin: 0 20px 0 0;
        .content {
          padding: 25px 20px;
          img {
            width: 80px;
            height: 80px;
          }
          > p:nth-of-type(1) {
            padding: 30px 0 15px 0;
            font-size: 18px;
          }
          > p:nth-of-type(2) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
    /deep/.swiper-button-prev,
    /deep/ .swiper-button-next {
      .iconfont {
        font-size: 65px;
      }
    }
    .move_content {
      height: 400px;
      margin: 80px 0 100px 0;
      .left {
        padding: 80px 50px;
        > p:nth-of-type(1) {
          font-size: 22px;
          line-height: 40px;
          span {
            margin-right: 15px;
          }
        }
        > p:nth-of-type(2) {
          font-size: 16px;
          line-height: 40px;
          padding-top: 25px;
        }
      }
      .right {
        padding: 20px 0;
        img {
          height: 300px;
          width: 350px;
        }
      }
      .pc_right {
        padding: 70px 0 60px 60px;
        img {
          width: 392px;
          height: 327px;
          //  width: 302px;
          // height: 252px;
        }
      }
    }
  }
}
@media screen and(min-width:1700.1px) {
  .slot_content {
    top: 350px;
    .border_p {
      margin: 35px 0;
    }
  }
}
</style>
