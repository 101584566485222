<template>
  <div class="banner">
    <img :src="options.bg_img" width="100%" height="100%" />
    <slot></slot>
    <!-- <div class="slot_box container">
      <slot></slot>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    options: {
      type: Object,
    },
  },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  height: 800px;
  position: relative;
  left: 0;
  z-index: 100;
  opacity: 0.8;
  background: #090924;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    opacity: .7;
    background-color: rgba(9, 9, 36, 1);
  }
}
@media screen and(max-width: 1200px) {
  .banner {
    top: -60px;
    height: 500px;
  }
}
@media screen and(min-width:1200.1px) {
  .banner {
    top: -80px;
    height: 800px;
  }
}
</style>